
.all-items {
  padding-left: 7.2%;
  padding-top: 30px;
}

.item-btn-text {
  margin-left: 6px;
}

.header-checkbox {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: solid 2px $orange;
  background: $orange-lightest;
  border-radius: 4px;

  &:checked {
    border: solid 2px red;
    background: $orange-lightest;
  }
}

.all-items-tr {
  &:hover {
    background: $sky-lightest;

    .popper-display {
      display: inherit;
    }
  }

  border-bottom:1px solid $teal-lighter;
  border-collapse:collapse;
}

.all-items-th {
  font-family: Gotham-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $ink-lighter;
  padding: 16px 60px 16px 0px;
  border-bottom: 1px solid $teal-lighter;
}

.all-items-th:first-child {
  padding-left: 30px;
  padding-right: 12px;
}

.all-items-td {
  //font-family: Gotham-Book;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: $ink;
  padding: 20px 60px 20px 0px;
  border: 0 none transparent;
}

.all-items-td:first-child {
  padding-left: 30px;
  padding-right: 12px;
}

.all-items-td:last-child {
  padding: 15px 30px 15px 0px;
}

.bold-items {
  font-family: Gotham-Medium;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: $ink;
}

.item-input {
  &:hover {
    background: $sky-lighter;
    color: $ink-lighter;
  }

  &:focus {
    background: $sky-lighter;
    color: $ink-lighter;
  }

  @include button($sky-lighter, $teal-lighter, $ink-lighter, 14px);
  padding: 2px 12px;
  width:300px;
  height:42px;
  cursor:text;
  opacity:0.5;
  position:relative;
  top:1px;
  text-indent:35px;
}

.product-search {
  position: absolute;
  top: 9px;
  left: 12px;
}

.Sellable {
  border: solid 1px $green-dark;
  background: $green-lightest;
  padding: 6px 12px;
  border-radius: 8px;
  font-family: Gotham-Medium;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $green-dark;
}

.Not {
  border: solid 1px $red;
  background: $red-lightest;
  padding: 6px 12px;
  border-radius: 8px;
  font-family: Gotham-Medium;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $red;
}

.delete {
  &:hover {
    background: $red;
    color: $white;
  }

  &:focus {
    background: $red-dark;
    color: $white;
  }

  @include button($transparent, $red, $red);
  padding: 13px 24px;
  line-height:14px;
  margin-left:12px;
}

table.custom-card {
  border-collapse: collapse;
}

.tab-content>.active {
  width: 100%
}

div.tab-content {
  padding-top: 15px;
}