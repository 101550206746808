

#products_in_order_page {
    padding: 12px 30px 12px 40px;

    .custom-search-field {
        margin-right: -11px;
    }

    #Popover1 {
        height: 34px;
        padding: 8px 14px;
        width: fit-content;
        margin-left: auto !important;
    }

    #filters_btn {
        position: relative;
        bottom: -15px;
        background-color: #0000;
        color: #498bdc;
        border: solid 1px #498bdc;
        border-radius: 5px;
        height: 34px;

        img {
            margin-right: 10px;
            padding-bottom: 2px;
            pointer-events: none;
        }

        span {
            padding-top: 2px;
            display: inline-block;
            pointer-events: none;
        }
    }

    #filters_container {
        position: relative;
    }

    #filters_div:before {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid white;
        content: "";
        position: absolute;
        top: -10px;
        left: 40px;
    }

    #filters_div {
        width: 320px;
        position: absolute;
        background-color: white;
        top: 58px;
        border-radius: 10px;
        box-shadow: 0 0 5px 0 #d0d0d0;
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        color: #505050;
        padding-top: 10px;
        display: none;
    }

    #filters_div>div {
        flex-basis: 42%;
        margin-bottom: 10px;
        
        select {
            display: block;
            width: 100%;
            height: 30px;
            color: #707070;
            background-color: white;
        }

        label {
            font-size: 12px;
            margin-bottom: 0 !important;
        }

        .sub__control {
            height: 35px !important;
            font-size: 14px;
        }
    }

    #filters_div>div:first-child {
        flex-basis: 62%;
        text-align: center;
        border: solid 1px#707070;
        color: #707070 !important;
        border-radius: 10px;
        padding: 5px 0;
        font-size: 14px;
        cursor: pointer;
    }

    #filters_div>div:nth-child(4) {
        flex-basis: 92% !important;

        .sub__control {
            width: 46% !important;
        }
    }

    #filters_div>div:nth-child(5) {
        flex-basis: 92% !important;

        .sub__control {
            width: 100% !important;

            .sub__indicators {
                display: none !important;
            }
        }
    }

    #filters_div>div:last-child {
        flex-basis: 100%;
        padding: 10px 10px 0 10px;
        margin-top: 18px;
        border-top: solid 1px #d0d0d0;

        button {
            float: right;
            margin-left: 10px;
            border-radius: 5px;
            font-size: 13px;
            padding: 5px 13px;
        }

        button:first-child {
            background-color: #498bdc;
            color: white;
        }

        button:last-child {
            background-color: white;
            color: #498bdc;
            border: solid 1px #498bdc;
        }
    }

    .custom-table td ul li {
        background-color: white;

        a {
            background-color: #0000;
        }
    }
}

#products_in_order_page>div:first-child {
    display: flex;
    margin-bottom: 30px;

    img {
        height: 20px;
        margin: 6px 15px 0 0;
    }

    div {
        span:first-child {
            font-size: 20px;
        }

        span:last-child {
            font-size: 11px;
            color: #212b36;
            //font-family: Gotham-Book;
        }
    }

    button {
        margin-left: auto;
        background-color: #498bdc;
        color: white;
        height: 38px;
        border-radius: 5px;
        border: none;
        width: 117px;
        font-size: 14px;
        margin-right: 5px;
    }
}