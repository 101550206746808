#confirm_products_list {
    .added_product_div {
        display: flex;
        font-size: 14px;
        color: #6b7783;
        align-items: flex-end;
        flex-wrap: wrap;
        padding: 20px 0;
        border-bottom: solid 1px #bbc0c6;

        label {
            color: #6b7783;
            font-size: 14px;
            margin-bottom: 8px;
        }

        .sub__control {
            width: 100%;
            min-height: 30px !important;
            height: 30px !important;
            border-radius: 4px; 
            color: black;
        }

        .sub__control>div {
            height: 28px;
            border-radius: 4px !important;
        }

        .sub__value-container {
            flex-wrap: nowrap;
        }

        input {
            display: block;
            height: 30px;
            border: solid 1px #bbc0c6;
            width: 100%;
            border-radius: 4px;
            padding-left: 10px; 
        }

        img {
            height: fit-content;
            margin: 0 0 7px 20px;
            cursor: pointer;
        }
    }

    .added_product_div>div {
        padding-right: 10px;
    }

    .added_product_div>div:nth-child(1) {
        width: 14% !important;
    }

    .added_product_div>div:nth-child(2) {
        width: 14% !important;
    }

    .added_product_div>div:nth-child(3) {
        width: 10% !important;
    }

    .added_product_div>div:nth-child(4) {
        width: 10% !important;
    }

    .added_product_div>div:nth-child(5) {
        width: 16% !important;
        display: flex;
        flex-wrap: wrap;

        label {
            flex-basis: 100%;
        }

        input {
            flex-basis: 50%;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        input+div {
            flex-basis: 50%;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }

    .added_product_div>div:nth-child(6) {
        width: 20% !important;
    }

    .added_product_div>div:nth-child(7) {
        width: 12% !important;
    }
}