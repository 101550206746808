::placeholder{
    color:#bfbfbf;
    font-size: 16px;
    font-weight: 500;
}

::-webkit-input-placeholder { 
    color: #bfbfbf;
    font-size: 16px;
    font-weight: 500;
  }

.landingInput{
    height:50px;
    width:100%;
    border:solid 1px #bfbfbf;
    padding-left:30px;
    border-radius: 4px;
}

// input:focus{
//     color:#212b36;
//     font-size: 16px;
//     font-weight: 500;    
// }  

img{
    color:red;
}
#root{
    height: 100%;
}