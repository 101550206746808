$transparent:transparent;
$white: #ffffff;
$black: #000000;
$blue-text: #485769;
$blue-dark: #3a6fb0;
$blue: #498bdc;
$blue-light: #66b9ea;
$blue-lighter: #98c7ee;
$blue-lightest: #c4ecf9;
$orange-text: #44663e;
$orange-light: #edd36d;
$orange-dark: #b0863a;
$orange: #dca849;
$orange-lighter: #f1dca3;
$orange-lightest: #f9f1c4;
$teal-text: #425a58;
$teal-dark: #437c7c;
$teal: #549c9c;
$teal-light: #75c3c3;
$teal-lighter: #a5cfcf;
$teal-lightest: #d4efef;
$sky-dark: #b2b5b9;
$sky: #dfe3e8;
$sky-lighter: #eff1f3;
$sky-light: #ebeef1;
$sky-lightest: #f9fafb;
$green-text: #6e5e42;
$green: #50b83c;
$green-dark: #409330;
$green-light: #70d454;
$green-lighter: #a1dc8a;
$green-lightest: #cff3b2;
$Ink-dark: #1a222b;
$ink: #212b36;
$ink-light: #2e3c4b;
$ink-lighter: #6b7783;
$ink-lightest: #8a99a9;
$ink-mild:#1d4067;
$red-text: #654846;
$red-dark: #b0403a;
$red: #dc5149;
$red-light: #ea7166;
$red-lighter: #eea198;
$red-lightest: #f9cfc4;
$faded-pink: #ffe6da;
$img-def-bg-color:#d8d8d8;

$header-background: rgba(255, 255, 255, 0.12);
$new-blue-lighter: #f6f6f7;