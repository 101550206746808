.date_modal {
    .modal-content {
        width: fit-content;
        overflow: hidden;
        margin-left: 80px;
        margin-top: 100px;
        border-radius: 0;

        .modal-header {
            padding: 0;

            button {
                display: none;
            }
        }

        .modal-body {
            width: fit-content;
            padding: 3px !important;

            .rdrCalendarWrapper {
                position: static;
                margin-left: 0;
                margin-top: 0;

                .rdrDefinedRangesWrapper {
                    display:none;
                }
            }
        }

        .modal-body>div:last-child {
            button {
                float: right;
                background-color: #498bdc;
                border-radius: 5px;
                border: none;
                color: white;
                padding: 5px 10px;
                margin: 0 5px 5px 0;
            }
        }
    }
}

.date_range_modal {
    .modal-content {
        width: fit-content;
        overflow: hidden;
        margin-left: -80px;
        margin-top: 100px;
        border-radius: 0;

        .modal-header {
            padding: 0;

            button {
                display: none;
            }
        }

        .modal-body {
            width: fit-content;
            padding: 3px !important;

            .rdrDateRangePickerWrapper {
                position: static;
                margin-left: 0;
                margin-top: 0;

                .rdrDefinedRangesWrapper {
                    display:none;
                }
            }
        }

        .modal-body>div:last-child {
            button {
                float: right;
                background-color: #498bdc;
                border-radius: 5px;
                border: none;
                color: white;
                padding: 5px 10px;
                margin: 0 5px 5px 0;
            }
        }
    }
}