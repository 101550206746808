

* {
    font-family: Gotham-Medium;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.text_36_normal_212b36{
    @include text(36px,normal,$ink);
}

// .text_30_normal_212b36{
//     @include text(30px,normal,$ink);
// }

.text_20_500_212b36{
    @include text(20px,500,#212b36)
}

.text_20_500_6b7783{
    @include text(20px,500,#6b7783)
}

.text_18_500_212b36{
    @include text(18px,500,$ink)
}

.text_18_500_bfbfbf{
    @include text(18px,500,#bfbfbf)
}

.text_16_600_blue{
    @include text(16px,600,$blue)
}

.text_16_500_90959a{
    @include text(16px,500,#90959a)
}

.text_16_500_bfbfbf{
    @include text(16px,500,#bfbfbf)
}

.text_16_500_6b7783{
    @include text(16px,500,#6b7783)
}

.text_16_normal_6b7783{
    @include text(16px,normal,#6b7783)
}

// .text_14_500_6b7783{
//     @include text(14px,500,#6b7783)
// }

// .text_14_normal_212b36{
//     @include text(14px,normal,$ink)
// }

.text_12_500_de4a49{
    @include text(12px,500,#de4a49)
}