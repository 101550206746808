@mixin heading-large(){
    font-size: 34px;
    line-height: 1.24;
    letter-spacing: -0.6px;
}

@mixin heading-medium(){
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.4px;
}

@mixin heading-numerical(){
    font-size: 24px;
    letter-spacing: -0.4px;
}

@mixin sub-heading(){
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.2px;
}

@mixin sub-text(){
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: -0.2px;
}

@mixin body-light(){
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.2px;
}

@mixin label-small(){
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

@mixin label-medium(){
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
}

@mixin label-large(){
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.2px;
}

@mixin label-extra-large(){
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.3px;
}

@mixin button($background, $borderColor, $color: $white, $font-size: 12px){
    width: auto;
    height: auto;
    border-radius: 4px;
    color: $color;
    background-color: $background;
    border: solid 1px $borderColor;
    cursor: pointer;
    font-size: $font-size;
}

@mixin disabled-button(){
    opacity: 0.46;
    cursor: not-allowed !important;
}

@mixin text($font-size,$fontweight,$color){
    font-size: $font-size;
    font-weight: $fontweight;
    color:$color
}