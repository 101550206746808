// .catalogue-div {
//     width: 190px;
//     border-radius: 8px;
//     background: url('./../../assets/images/dropdown.png');
//     background-color: #fff !important;
//     background-repeat: no-repeat !important;
//     background-position: 94% 50% !important;
//     padding: 10px;
//     position: relative;
//     .catalogue-dropdown {
//         display: none;
//         position: absolute;
//         z-index: 1;
//         left: 0;
//         top: 45px;
//         width: 190px;
//         border-radius: 0 0 4px 4px;
//         box-shadow: 0 0 2px 0 rgba(33, 43, 54, 0.4), 0 4px 4px 0 rgba(33, 43, 54, 0.18);
//         background-color: #ffffff;
//         ul {
//             text-decoration: none;
//             list-style: none;
//             padding-left: 0;
//             li {
//                 align-items: center;
//                 display: flex;
//                 height: 45px;
//                 .link-div {
//                     padding: 4px 18px !important;
//                 }
//             }
//             li:hover {
//                 background: #dfe3e8;
//             }
//         }
//     }
// }
// .catalogue-div:hover {
//     border-radius: 8px 8px 0 0;
//     background: url('./../../assets/images/up.png');
//     .catalogue-dropdown {
//         display: block;
//     }
// }

.navSelect {
    div.subNavSelect__control {
        border: solid 1px white;
        border-radius: 4px;
        background-color: white;
        height: 38px;
        list-style: none;
    }
}

.clicked {
    .subNavSelect__indicators {
        background-image: url('../../images/up.png') !important;
    }
}

.subNavSelect__indicators {
    border-radius: 0 8px 8px 0;
    width: 40px;
    background-image: url('../../images/dropdown.png');
    background-color: white !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    span {
        margin: 0;
        border: solid 0.5px white;
    }
    .subNavSelect__indicator {
        color: gray;

        svg {
            display: none;
        }
    }
}
.catalogue-path {
    align-items: center;
    margin-left: 20px;
}
