.custom-table-wrapper {
  //padding-top:1%;
  //padding-right:5%;
  table-layout: auto;
  border: 1px solid #cfcfd4;
  border-radius: 4px;
  background: white;
  //box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.2);
}

span.react-bootstrap-table-pagination-total {
  padding-left: 20px;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.custom-modal-table-wrapper {
  padding-top: 2%;
  padding-right: 0%;
}

.custom-table {
  //background:$white;
  border-radius: 4px;
  z-index: 120;
  margin-bottom: 40px;
}

// .custom-table tr:hover :not(th){
//     background-color: #f0f0f0;
//     & input[type="checkbox"] {
//         display: flex;
//       }
// }

.custom-table tbody tr td {
  border-bottom: 0.5px solid #efefef;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
  position: relative;
  vertical-align: middle;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333853;
  cursor: pointer;
}

.custom-table thead tr th {
  border-bottom: 1px solid $teal-lighter;
  //padding-top: 20px;
  // padding-bottom: 20px;
  text-align: left;
  background: #f6f6f7;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
}

.custom-search-field,
.modal-custom-search-field {
  background-color: $white;
  padding: 13px 18px;
  float: right;
  position: absolute;
  margin-bottom: 18px;

  color: $ink;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #efefef !important;
}

.custom-search-field {
  position: absolute;
  width: 20%;
  right: 4.2%;
  margin-top: -31px;
}

.modal-custom-search-field {
  width: 50%;
  top: 20px;
  right: 5.5%;
}

.custom-search-field:focus,
.modal-custom-search-field:focus {
  border-color: none;
  box-shadow: none;
}

//  input[type="checkbox"].item-checkbox {
//     display: none;
//     -webkit-appearance: none;
//     width: 18px;
//     height: 38px;
//     border: solid 2px $orange;
//     background: $orange-lightest;
//     border-radius: 4px;
//     &:checked {
//         content: '&#10004;';
//       display: block;
//       color:$ink;
//     //   position: absolute;
//     //   top:35%;
//     //   left:35%;
//       border: 3px solid $orange;
//     //   -webkit-transform: rotate(-45deg);
//     //   -moz-transform: rotate(-45deg);
//     //   -o-transform: rotate(-45deg);
//     //   -ms-transform: rotate(-45deg);
//     //   transform: rotate(-45deg);

//     }

// }
// .item-checkbox {
//     display: none;
//     -webkit-appearance: none;
//     width: 18px;
//     height: 18px;
//     border: solid 2px $orange;
//     background: $orange-lightest;
//     border-radius: 4px;
//     &:checked {
//       border: solid 2px red;
//       background: $orange-lightest;
//     }
//   }

.react-bootstrap-table-pagination {
  margin: auto 0;
  margin-bottom: 18%;
  margin-right: -4px;
  div {
    padding: 0;
  }
}

.custom-table {
  border-radius: 4px;
  border: none;
  // overflow: hidden;

  thead {
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
  }

  th:first-child {
    border-top-left-radius: 4px !important;
    padding-left: 15px !important;
  }

  td:first-child {
    padding-left: 15px !important;
  }

  th:last-child {
    border-top-right-radius: 4px !important;
    width: 4% !important;
  }

  thead tr {
    border-bottom: none !important;
  }

  tbody tr {
    background-color: white;
  }

  // tbody tr:nth-child(2n+1) {
  //     background-color: #f9fafb;
  // }

  th {
    // padding-top: 20px !important;
    // border: none;
    //width: 16% !important;
    border: none !important;
    padding: 10px !important;
    height: 40px;
  }

  td {
    border-bottom: 1px solid #cfcfd4 !important;
    border-top: none !important;
    padding: 6px 10px !important;
    height: 40px !important;
    //padding: 20px !important;
    // width: 16% !important;

    img {
      cursor: pointer;
      position: relative;
    }

    ul {
      position: absolute;
      top: 33px;
      right: 0px;
      background-color: white;
      list-style: none;
      padding: 0 !important;
      width: 100px;
      z-index: 2;
      border-radius: 10px;
      box-shadow: 0 0 4px 0#d0d0d0;
      display: none;

      li {
        background-color: white;
        padding: 10px 0;
        text-align: center;
        cursor: pointer;

        a {
          color: #707070 !important;
        }
      }

      li:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      li:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      li:hover {
        background-color: #d0d0d044 !important;
      }
    }

    img:hover + ul,
    ul:hover {
      display: block !important;
    }
  }

  td:last-child {
    //   width: 4% !important;
    //padding-left: 20px !important;
  }
}

.react-bootstrap-table {
  margin-bottom: 12px;
}

//boostrap table pagination//
.pagination {
  float: right;
}

.page-item{
    border-radius: 4px !important;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.2) !important;
    //border: solid 1px #cfcfd4 !important;
    background-color: #ffffff !important;
    margin-right: 4px !important;
    width: 30px !important;
}

.page-link{
    border-radius: 4px !important;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.2) !important;
    border: solid 1px #cfcfd4 !important;
    padding: .3rem 0.7rem !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.29 !important;
    letter-spacing: normal !important;
    text-align: center !important;
}

.page-item.active .page-link {
    background-color: $blue;
}

.dropdown-toggle{ //pagination
    width: 65px;
    height: 31px;
    color: #7b7f82;;
    border-radius: 4px;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.2);
    border: solid 1px #cfcfd4;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
}

// .custom-search-field {
//     margin-right: -10px;
// }
