.font-bold {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
   -webkit-font-smoothing: antialiased;
    font-weight: 600;
}

.font-normal {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
   -webkit-font-smoothing: antialiased;
}

.font-black-1 {
    color: #242424;
}

.btn-primary-2 {
    background-color: #498bdc;
    color: white;
    font-weight: 400;
    padding: 12px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    line-height: 14px;
}

::placeholder {
    color: #cfcfd4 !important;
    font-size: 14px !important;
}

.input-2:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $new-blue-lighter !important;
    caret-color: #498bdc;
}

.no-sep-select {
    .css-bg1rzq-control {
        min-height: 36px;
        height: 36px;
    }

    .css-1szy77t-control {
        background-color: $new-blue-lighter;
    }

    .css-1cr66sn-control{
        background-color: $new-blue-lighter;
    }

    .css-1cr66sn-control:hover {
        border-color: #cfcfd4 !important;
        box-shadow: none !important;
    }

    .css-1szy77t-control:hover {
        border-color: #cfcfd4 !important;
        box-shadow: none !important;
    }

    .css-1hwfws3,.css-1wy0on6 {
        cursor: pointer;
    }

    .css-bgvzuu-indicatorSeparator {
        display: none !important;
    }
}

.no-sep-select.dropdown-no-pe {
    pointer-events: none;
}

.checkbox-2 {
    -webkit-appearance: none;
    border: solid 2px #989C9E;
    background: white;
    height: 18px !important;
    width: 18px !important;
    margin-top: 0.1rem !important;

    &:checked {
        border: none;
        background: url('../../icons/selected.svg');
        background-size: 100%;
    }
}

.checkbox-3 {
    -webkit-appearance: none;
    border: solid 2.2px #cfcfd4;
    background: white;
    height: 20px !important;
    width: 20px !important;
    margin-top: 0;
    border-radius: 5px;

    &:checked {
        border: none;
        background: url('../../icons/check_box.png');
        background-size: 100%;
    }
}

.checkbox-4 {
    -webkit-appearance: none;
    border: solid 2px #cfcfd4;
    background: white;
    height: 18px !important;
    width: 18px !important;
    margin-top: 0;
    border-radius: 4px;

    &:checked {
        border: none;
        background: url('../../icons/blueBox.svg');
        background-size: 100%;
    }
}

.country-li:hover {
    background-color: #FAFAFB;
    cursor: pointer;
}

.sb-item:focus, .sb-item-select {
    border: solid 2px #498bdc !important;
    background-color: rgba(73, 139, 220, 0.11) !important;
    
    p {
        font-weight: 600;
        color: #212b36;
    }
}

.sm-li {
    width: 100%;
    color: #7b7f82;
    font-weight: 600;
    font-size: 600;
    padding-left: 20px;
    padding-top: 10px;
    height: 39px;
    cursor: pointer;
}

.ssm-li {
    width: 100%;
    color: #7b7f82;
    padding-left: 40px;
    padding-top: 10px;
    height: 39px;
    cursor: pointer;
}

.sm-li-selected {
    border-left: solid 4px #498bdc;
    background-color: $new-blue-lighter;
    color: #242424;
    padding-left: 16px;
}

.ssm-li-selected {
    border-left: solid 4px #498bdc;
    background-color: $new-blue-lighter;
    color: #242424;
    padding-left: 36px;
}

.sm-expand-li {
    div {
        display: flex;

        span {
            width: 100%;
            color: #7b7f82;
            font-weight: 600;
            font-size: 600;
            padding-left: 20px;
            padding-top: 10px;
            height: 39px;
            cursor: pointer;
        }

        img {
            margin-left: auto;
            margin-right: 7.7%;
        }
    }
}