#generic_report2 {
    #filters_div>div:first-child {
        flex-basis: 42% !important;
        border: none !important;
        color: #505050 !important;
    }

    #filters_div .search-select {
        flex-basis: 90% !important;

        .sub__indicators {
            display: none !important;
        }
    }
}

#generic_report2>div:nth-child(2)>div:nth-child(1) {
    background-color: #0000 !important;
    box-shadow: none !important;
    display: flex !important;
    flex-basis: 100% !important;
}