#generic_report5 {
    #Popover1 {
        margin-left: auto !important;
    }

    #filters_div>div:first-child {
        flex-basis: 42% !important;
        border: none !important;
        color: #505050 !important;
    }
    #filters_container {
        display: none !important;
    }
}

#generic_report5>div:nth-child(2)>div:nth-child(1) {
    background-color: #0000 !important;
    box-shadow: none !important;
    display: flex !important;
    flex-basis: 100% !important;
    flex-wrap: wrap !important;
}