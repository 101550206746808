
.sidebar {
  display: flex;
  flex-direction: column;
  width: 4.7%;
  min-width: 70px;
  background-color: #ffffff;
  // border-width: 0px 1px 0px 0px;
  // border-style: solid;
  // border-color: $teal-lighter;
  height: 100vh;
}

.fixed-sidebar {
  position: fixed;
  z-index: 3;
  top: 56px;
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.1);
}

.sidebar-item {
  width: 100%;
  margin: 0px 0px 0px 0px;
  height: 66px;
  //border-radius: 8px;
  background-color:#ffffff;
}

.sidebar-item-active {
  float: left;
  width: 100%;
  margin: 0px 0px 0px 0px;
  height: 76px;
  //border-radius: 8px;
  background-color: $sky-lighter;
}

.sidebar-item:hover {
  float: left;
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: 76px;
  //border-radius: 8px;
  background-color:  $sky-lighter;
}

.sidebar-item-icon {
  display: block;
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-top: 14px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
}

.sidebar-item-label {
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7783;
  text-align: center;
}

.vertical_ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 225px;
  color: #6b7783;
  font-size: 13px;
  text-decoration: none;
  //background-color: #f1f1f1;
}

.vertical_a {
  display: block;
  color: #6b7783;
  padding: 10px 12px;
  text-decoration: none;
  font-size: 13px;
  padding-left: 32px;
}

/* Change the link color on hover */
.vertical_a:hover {
  background-color:#eff1f3;
  color: black;
}

.vertical_a:active {
  background-color:#eff1f3;
  color:#498bdc;
} 


///////////////////////////////////////////////////////////////////

.drawer {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 70px;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 55px;    
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.1);
  //border: 0.5px solid #a7a7a7;
}

.drawer .closebtn { 
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
  margin-left: 200px;
  margin-top: 200px;
}

.openbtn:hover {
  background-color: #444;
}

#mainT {
  transition: margin-left .5s;
  padding: 16px;
}

.master-sidebar{
  background-color:$new-blue-lighter;
  width:auto;
  padding-left:0%;
  padding-top:70px;
}

.null-margin{
  margin: 0px;
}

/////////////////////////////sidebar submenu////////////////////////////
@keyframes efect {
  from {
    width: 0;
    opacity: 0.1;
  }

  to {
    width: 300px;
    opacity: 0;
  }
}

.sub-menu-item {
  cursor: pointer;
  transition: 1s ease;
}

.sub-menu-item:hover {
  background-color:#fff;
  color: black;
}

.sub-menu-item:after {
  animation: efect 0.8s ease;
  opacity: 0;
}

.sub-menu-none {
  display: none;
  animation: slideIn 0.4s ease;
  z-index: 1;
}

.sub-menu-block {
  display: block;
  animation: slideIn 0.4s ease;
  z-index: 1;
}

.sub-menu-header{
  text-decoration: none;
  // padding-top: 10px;
  // padding-bottom: 10px;
}

.sub-menu-header:hover{
  background-color:#eff1f3;
  color: black;
}

.sub-menu-header:active{
  background-color:#eff1f3;
  color:#498bdc;
}

@keyframes slideIn {
  from {
    margin-top: -20px;
  }

  to {
    margin-top: 0;
  }
}

