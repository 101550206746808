#generic_report4 {
    #filters_div>div:nth-child(8) {
        flex-basis: 42% !important;

        .sub__control {
            width: 100% !important;
        }
    }

    #filters_div>div:nth-child(9){
        flex-basis: 42% !important;

        .sub__control {
            width: 100% !important;
        }

        .sub__indicators {
            display: block !important;
        }

        input {
            margin-top: 24px;
        }
    }
}