

.catalogue-combo {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 64px;
}

.catalogue-header-combo {
    font-family: Gotham-Medium;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $blue;
}

.new-product-combo {
    //font-family: Gotham-Book;
    font-size: 34px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.24;
    letter-spacing: -0.6px;
    color: $ink;
    float: left;
    padding-top: 10px;
}

.cancel-combo {
    margin-left: 61.5%;
    // margin-left: 53.5%;
}

.save-combo {
    margin-left: 12px;
}

.switch-div-combo {
    margin: 6px auto 20px;
}
.create-row-combo {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.create-col-1-combo {
    flex-basis: 67%;
}

.create-col-2-combo {
    margin-left: 18px;
    flex-basis: 28%;
}

.create-title-div-combo {
    padding: 30px 0 30px 36px;
}

.create-combo-group-div-combo {
    margin-top: 18px;
    padding: 30px 0 30px 36px;
    margin-bottom: 2rem;
}

.create-image-div-combo {
    padding: 21px 0 26px;
}
.combo-groups-title {
    color: #6b7783;
}
.custom-table-combo thead tr th {
    font-size: 14px;
}

.create-image-combo {
    margin-top: 26px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    width: 40%;
    height: 114px;
}
.create-image-text {
    text-align: center;
}
.browse-image-combo {
    margin-left: 0;
}

.drag-drop-img-combo {
    margin-left: 0;
}

.create-organization-div-combo {
    margin-top: 16px;
    padding: 24px 0 24px 24px;
}
.instruction-modal{
    color: #007bff;
    padding-left: 12px;
}

.cancel-modal {
    position: absolute;
    left: 30px;
}
.calc-combo{
    height: 42px;
    position: relative;
    top: 0;
}
.edit-combo-btn{
    padding-right: 10px;
    color: #007bff;
    background: none;
    border: none;

}
.custom-table2 thead tr th:nth-child(1){
    display: none;
}
.custom-table2 tbody tr td:nth-child(1){
    display: none;
}






.file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 100px;
    width: 100%;
  }
  
  .file-drop > .file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;
    /* --------------- */
  
    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
  
    text-align: center;
    /* --------------- */
  }
  
  .file-drop > .file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border: none;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: none;
    z-index: 50;
    opacity: 1.0;
    /* --------------- */
  
    /* typography */
    color: white;
    /* --------------- */
  }
  
  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color: #ff6e40;
    box-shadow: 0 0 13px 3px #ff6e40;
  }