
* {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
   -webkit-font-smoothing: antialiased;
   line-height: normal;
   font-weight: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
    margin: 0px;
    background-color: $new-blue-lighter;
  //background-color: #f4f6f8;
}

a {
    text-decoration: none;
    cursor: pointer;
    //font-family: Gotham-Book;
}

a.btn {
    //font-family: Gotham-Medium;
}

a:hover {
    text-decoration: none;
}

button {
    &:focus {
        outline: none;
    }
}

button.btn:disabled {
    &:hover {
        &.btn-md-primary,
        &.btn-lg-primary,
        &.btn-sm-primary {
            background: $blue;
        }
        &:hover.btn-md-secondary,
        &.btn-lg-secondary,
        &.btn-sm-secondary {
            background: $white;
            color: $blue;
        }
    }
    @include disabled-button()
}

.btn.btn-sm-primary {
    &:hover {
        background: $blue-light;
    }
    &:focus {
        background: $blue-dark;
    }
    @include button($blue, $blue-dark);
    padding: 8px 24px;
}

.btn.btn-md-primary {
    &:hover {
        background: $blue-light;
    }
    &:focus {
        background: $blue-dark;
    }
    @include button($blue, $blue-dark);
    //padding: 13px 24px;
    padding: 9px 12px;
    font-size:14px;
    line-height:14px;
    height: 34px;
    border-radius: 4px;
    margin-left: 10px;
}

.btn.btn-lg-primary {
    &:hover {
        background: $blue-light;
    }
    &:focus {
        background: $blue-dark;
    }
    @include button($blue, $blue-dark, $white, 16px);
    padding: 18px 24px;
}

.btn.btn-sm-secondary {
    &:hover {
        background: $blue;
        color: $white;
    }
    &:focus {
        background: $blue-dark;
        color: $white;
    }
    @include button($transparent, $blue, $blue);
    padding: 8px 24px;
}

.btn.btn-md-secondary {
    &:hover {
        background: $blue;
        color: $white;
    }
    &:focus {
        background: $blue-dark;
        color: $white;
    }
    @include button($transparent, $blue, $blue);
    padding: 10px 14px;
    font-size:14px;
    line-height:14px;
}

.btn.btn-lg-secondary {
    &:hover {
        background: $blue-light;
        color: $white;
    }
    &:focus {
        background: $blue-dark;
        color: $white;
    }
    @include button($transparent, $blue, $blue, 16px);
    padding: 18px 24px;
}

.lineartab-btn-r{
    background-color: #ffffff;
    color: #6b7783;
  border-radius: 4px;
  border: solid 0.5px #dfe3e8;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
}
.lineartab-btn-l{
    background-color: #ffffff;
    color: #6b7783;
  border-radius: 4px;
  border: solid 0.5px #dfe3e8;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  cursor: pointer;
}

.linear-btn-active{
    background-color: #f3f7fd; 
    color: #212b36;
}

/* Harshit's input part */

button {
    outline: none;
}

input {
    border-radius: 4px;
    outline: none;
}

select {
    outline: none;
    border-radius: 4px;
}

input:disabled {
    background-color: $sky-lightest;
    border: solid 1px $sky-lighter;
}

.default {
    background-color: $transparent;
    border: solid 1px $teal-lighter;
    font-size: 14px;
    padding: 12px 18px;
    color: $ink-lightest;
    &::placeholder {
        opacity: 0.46;
    }
}

.default:focus {
    background-color: $white;
    border: solid 1.5px $blue;
}

.error {
    background-color: $faded-pink;
    border: solid 1px $red;
}

.error:focus {
    background-color: $faded-pink;
    border: solid 1.5px $red;
}

.avatar {
    vertical-align: middle;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-left: 12px;
  }

.header {
    height: 56px;
    background-color:  $ink-mild;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-shadow: 0 3px 6px -3px #c9c9c9;
}

.header-logo {
    display: flex;
    width: 4.7%;
    justify-content: center;
    align-items: center;
    background: #498bdc;
}

.header .search-bar {
    width: 73%;
}

.fixed-header {
    position: fixed;
    width: 100vw;
    top: 0px;
    z-index: 999;
}

.header .search-bar .global-search {
    &::placeholder {
        color: $teal-light;
    }
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: $header-background;
    border: none;
    margin-top: 10px;
    color: $teal-light;
    text-indent: 35px;
    @include label-large()
}

.search-bar {
    position: relative;
}

html {
    background-color: $new-blue-lighter;
}

.header .header-right {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    & button {
        background: transparent;
        border: none;
        cursor: pointer;
    }
    span.username {
        font-size: 16px;
        color: $teal-light;
        position: relative;
        top: -6px;
        padding-right: 0.5vw;
    }
}

.custom-card {
    background: $white;
    border-radius: 4px;
}

.login-md-primary {
    &::placeholder {
        color: $blue;
    }
    &:hover {
        background: $white;
    }
    &:focus {
        background: $white;
        cursor: text;
    }
    @include button($white, $blue, $blue);
    padding: 8px 24px;
}

.icon-btns {
    &:hover {
        background: transparent;
        color: $blue;
    }
    &:focus {
        background: transparent;
        color: $blue;
    }
    @include button(transparent, $blue, $blue, 14px);
    padding: 7px 12px 6px 9px;
    //font-family: Gotham-Medium;
    line-height:1;
}

.icon-btns-new{
   background: transparent;
    width: 100%;
    border-radius: 4px;
    border: solid 0.5px #bbc0c6;
    height: 30px;
    color: #212b36;
   // font-family: Gotham-Medium;
    font-size: 14px;
    line-height: 1;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: none;
    border-bottom: 2px solid #489bdc;
    background: transparent;
}

.custom-date-range {
    position: absolute;
    margin-left: -1%;
    margin-top: 4%;
    z-index:150;
}

.popper-display {
    position: relative;
}

.main-component-wrapper {
    padding-top: 10px !important;
    padding-left: 23% !important;
    padding-right: 4.2% !important;
    position: relative;
}

.modal-center-custom {
    position: absolute;
    bottom: 0;
    widows: 100%;
    margin: 0;
    border-radius: 0;
    width: 100%;
}

.modal-center {
    top: 15%;
    max-width: 100%;
}

.modal-width{
    width: 80%;
}

div.modal {
    & .modal-dialog {
        min-width: 30%;
        border-radius: 4px;
        & .modal-content {
            & .modal-header {
                padding-top: 24px;
                padding-bottom: 30px;
                font-size: 24px;
               // font-family: 'Gotham-Medium';
                color: $ink-lighter;
                font-weight: 500;
                border-bottom: 1px solid $teal-lighter;
                letter-spacing: -0.4px;
                & button.close {
                    display: none;
                }
            }
            & .modal-body {
                padding: 30px;
            }
            & .modal-footer {
                border-top: 1px solid $teal-lighter;
            }
        }
    }
}

form.form {
    & label {
        color: $ink-lighter;
        font-size: 14px;
        letter-spacing: -0.2px;
    }
    & div.form-group {
        margin-bottom: 10px;
    }
}

.page-header {
    display: flex;
    margin: 0 !important;
    padding-right: 5px;

    img:first-child {
        height: 17px;
        margin: 4px 7px 0 0;
    }

    .btn:nth-child(3), button:nth-child(3) {
        margin-left: auto;
    }
}

.page-header-span {
    padding-left: 25px;
    display: block;
}

.sortable .order {
    padding: 0 !important;
}

#pageDropDown .caret {
    display: none !important;
}

.pagination {
    margin-top: 0 !important;
}

.order .dropup .caret {
    margin: 4px 0 10px 0 !important
}

.order .dropdown .caret {
    margin: 17px -8px 10px 5px !important
}

.react-bootstrap-table-pagination {
    font-size: 13px !important;
}

.react-bootstrap-table-pagination>div:first-child {
    display: flex;
}

.react-bootstrap-table-pagination-total {
    padding-top: 5px !important;
}
/* bootstrap part */