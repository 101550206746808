#order_detail_div {
    padding: 12px 30px 12px 40px;

    #main_div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: start;
    }

    #main_div>div:first-child {
        background-color: white;
        border-radius: 4px;
        width: 71%;
        padding-top: 20px;
    }

    #main_div>div:first-child>p {
        margin-bottom: 40px !important;
        color: #498bdc;
    }

    #main_div>div:first-child>p {
        padding: 0 30px;
    }

    #main_div>div:first-child>div {
        padding: 0 30px;

        .product-item {

        }

        .product-item-head {
            display: flex;
            background-color: white;
            border-radius: 4px;
            min-height: 70px;
            color: #6b7783;
            font-size: 14px;
            font-weight: lighter;
            align-items: center;
            margin-bottom: 18px;

            img:first-child {
                height: 90%;
                width: auto;
                margin-right: 38px;
                margin-left: 18px;
            }

            img:last-child {
                width: fit-content;
                margin-left: 10px;
            }
        }

        .product-item-head:hover {
            background-color: #e0e0e0;
            cursor: pointer;
        }

        .product-item-head>div {
            display: flex;
            flex-grow: 2;
            height: 100%;
            align-items: center;

            p {
                margin: 2px 0 !important;
            }

            div {
                width: 22.22%;
                text-align: right;
            }

            div:first-child {
                width: 33.33%;
                text-align: left;

                p:first-child {
                    color: #498bdc;
                }
            }
        }

        .product-item-head>div>p {
            margin: 2px 10px !important;
        }

        .product-item-body {
            height: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transition: ease 0.3s all;
            overflow: hidden;
            flex-wrap: wrap;
        }

        .product-item-body>div {
            background-color: #e2eaf1;
            margin: 5px;
            border-radius: 4px;
            padding: 5px 10px;
            color: #6b7783;
        }

        .product-item-body p {
            margin-bottom: 3px;
            font-weight: bold;
            font-family: 'Varela Round', sans-serif;
            font-size: 15px;
        }

        .product-item-body>div span {
            font-weight: lighter;
            font-family: 'Varela Round', sans-serif;
            font-size: 15px;
        }

        .product-item-body>div>div {
            display: flex;
        }

        .product-item-body>div>div>div {
            margin-right: 14px;
        }

        .product-item-body>div>div>div:last-child {
            margin-right: 0 !important;
        }

        .product-item-body>div:last-child>div>div>div:last-child {
            margin-right: 0 !important;
        }

        .product-item-body>div span:last-child {
            padding-left: 5px;
        }
    }

    #main_div>div:first-child>div:nth-child(2) {
        height: fit-content;
        max-height: 450px;
        overflow-y: scroll;
    }

    #main_div>div:first-child>div:nth-child(3) {
        padding: 30px 30px 30px 30px;
        background-color: #fffaec;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-end;
        flex-direction: column;
        border-top: dashed 2px #d0d0d0;
    }

    #main_div>div:first-child>div:nth-child(3)>div {
        display: flex;
        color: #485562;
        margin-bottom: 10px;
    }

    #main_div>div:first-child>div:nth-child(3)>div:last-child {
        font-weight: bold;
    }

    #main_div>div:first-child>div:nth-child(3)>div>div {
        width: 100px;
        text-align: right;
    }

    #main_div>div:last-child {
        width: 28%;
    }

    #main_div>div:last-child>div:nth-child(1), #main_div>div:last-child>div:nth-child(2) {
        background-color: white;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 12px;

        span {
            width: 40%;
            display: inline-block;
            font-size: 14px;
            color: #6b7783;
        }

        span:nth-child(2) {
            width: 1%;
        }

        span:last-child {
            width: 59%;
            color: #485562;
            padding-left: 10px;
        }
    }

    #main_div>div:last-child>div:nth-child(1)>div, #main_div>div:last-child>div:nth-child(2)>div {
        display: flex;
    }

    #main_div>div:last-child>div:nth-child(2) {
        background-color: #fffaec;

        span {
            width: 49% !important;
        }

        span:nth-child(2) {
            width: 1% !important;
        }

        span:last-child {
            width: 50% !important;
        }
    }

    #main_div>div:last-child>div:nth-child(3) p:first-child {
        margin-bottom: 8px;
    }

    #main_div>div:last-child>div:nth-child(3) p:last-child {
        font-size: 12px;
        color: #acb3ba;
    }

    #main_div>div:last-child>div:nth-child(1)>div:first-child, #main_div>div:last-child>div:nth-child(2)>div:first-child {
        color: #498bdc;
        margin-bottom: 20px;
    }

    #main_div>div:last-child>div:nth-child(3) p:first-child {
        margin-bottom: 8px;
    }

    #main_div>div:last-child>div:nth-child(3) p:last-child {
        font-size: 12px;
        color: #acb3ba;
    }
}

#order_detail_div>div:first-child {
    display: flex;
    margin-bottom: 30px;

    img {
        height: 20px;
        margin: 6px 20px 0 0;
    }

    div {
        span:first-child {
            font-size: 20px;
        }

        span:last-child {
            font-size: 11px;
            color: #212b36;
        }
    }

    button:last-child {
        background-color: #498bdc;
        color: white;
        height: 38px;
        border-radius: 5px;
        border: none;
        width: fit-content;
        font-size: 14px;
        margin-right: 5px;
        margin-left: auto;
        padding: 0 20px;
    }
}