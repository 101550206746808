

// .master-sidebar{
//   background-color:$sky-lighter;
//   width:auto;
//   padding-left:5.8%;
//   padding-top:56px;
// }

.item-header{
  display:flex;
  flex-direction:row;
  padding:12px 0px 12px 26px;
  // border-bottom: 1px solid;
  // border-color: $teal-lighter;
}

.header-divs{
	padding:12px 18px 12px 18px;
  margin-right:6px;
}

.header-divs:hover{
	background-color:$sky;
	border-radius:8px;
}

.item-text{
  font-family: Gotham-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $ink;
}

.header-divs-active{
	background-color:$sky;
	border-radius:8px;
}