#PO {
    padding-top: 10px !important;
    padding-left: 7.5% !important;
    padding-right: 2.8% !important;
    font-family: 'Varela Round', sans-serif;

    #Popover1 {
        margin-top: 15px;
        height: 34px;
        padding: 8px 0 0 23px;
    }

    #filters_btn {
        position: relative;
        bottom: -15px;
        background-color: #0000;
        color: #498bdc;
        border: solid 1px #498bdc;
        border-radius: 5px;
        height: 34px;

        img {
            margin: 4px 10px 0 0;
            padding-bottom: 2px;
            pointer-events: none;
        }

        span {
            padding-top: 0;
            display: inline-block;
            pointer-events: none;
            color: #498bdc;
            margin-bottom: 6px;
        }
    }

    #filters_container {
        margin-left: auto;
        position: relative;
    }

    #filters_div:before {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid white;
        content: "";
        position: absolute;
        top: -10px;
        right: 40px;
    }

    #filters_div {
        width: 320px;
        position: absolute;
        background-color: white;
        top: 58px;
        right: 0;
        border-radius: 10px;
        box-shadow: 0 0 5px 0 #d0d0d0;
        z-index: 1200;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        color: #505050;
        padding-top: 10px;
        display: none;
    }

    #filters_div>div {
        flex-basis: 42%;
        margin-bottom: 10px;
        
        select {
            display: block;
            width: 100%;
            height: 30px;
            color: #707070;
            background-color: white;
        }

        label {
            font-size: 12px;
            margin-bottom: 0 !important;
        }

        .sub__control {
            height: 35px !important;
            font-size: 14px;
        }
    }

    #filters_div>div:nth-child(8) {
        flex-basis: 92% !important;

        .sub__control {
            width: 46% !important;
        }
    }

    #filters_div>div:nth-child(9){
        flex-basis: 91% !important;

        .sub__control {
            width: 100% !important;
        }

        .sub__indicators {
            display: none;
        }
    }

    #filters_div>div:first-child {
        flex-basis: 62%;
        text-align: center;
        border: solid 1px#707070;
        color: #707070 !important;
        border-radius: 10px;
        padding: 5px 0;
        font-size: 14px;
        cursor: pointer;
    }

    #filters_div>div:last-child {
        flex-basis: 100%;
        padding: 10px 10px 0 10px;
        margin-top: 18px;
        border-top: solid 1px #d0d0d0;

        button {
            float: right;
            margin-left: 10px;
            border-radius: 5px;
            font-size: 13px;
            padding: 5px 13px;
        }

        button:first-child {
            background-color: #498bdc;
            color: white;
        }

        button:last-child {
            background-color: white;
            color: #498bdc;
            border: solid 1px #498bdc;
        }
    }

    .react-bootstrap-table {
        overflow-x: scroll;

        td, th {
            min-width: 80px !important;
            padding-left: 5px !important;
            padding-right: 5px !important;
            padding-bottom: 15px !important;
            padding-top: 15px !important;
        }

        td:first-child, th:first-child {
            padding-left: 10px !important;
            width: 12% !important;
        }

        td:nth-child(2), th:nth-child(2), td:nth-child(5), th:nth-child(5), td:nth-child(6), th:nth-child(6) {
            width: 20% !important;
        }

        td:nth-child(3), th:nth-child(3), td:nth-child(4), th:nth-child(4) {
            width: 14% !important;
        }

        td:last-child, th:last-child {
            padding-left: 10px !important;
            width: fit-content !important;
        }

        tbody {
            tr {
                cursor: pointer;
            }
        }
    }

    .v2-tooltip {
        width: 300px;
        border-radius: 4px;
        position: absolute;
        top: 0;
        right: -300px;
        z-index: 2;
        background-color: white;
        box-shadow: 0 0 3px 0 #ddd;
        display: none;
    }

    .v2-tooltip>div:first-child {
        font-size: 16px;
        background-color: #f9fafb;
        padding: 8px;
    }

    .v2-tooltip>div:last-child {
        font-size: 14px;
        color: #6b7783;
        padding: 8px 8px 12px 8px;
    }

    .v2-tooltip>div:last-child ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .v2-tooltip>div:last-child ul li {
        display: flex;
        margin-bottom: 4px;
        justify-content: space-between;
    }

    .v2-tooltip>div:last-child ul li span:last-child {
        color: #498bdc;
    }

    .v2-tooltip:before {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid #f9fafb;
        content: "";
        position: absolute;
        top: -10px;
        left: 30px;
    }
}

#PO>div:first-child {
    display: flex;
    margin-bottom: 30px;
    margin-top: 15px;

    img {
        height: 20px;
        margin: 6px 20px 0 0;
    }

    div {
        span:first-child {
            font-size: 20px;
        }

        span:last-child {
            font-size: 11px;
            color: #212b36;
        }
    }
}

#PO>div:nth-child(2) {
    display: flex;
    margin-bottom: 30px;
    align-items: flex-start;

    .pi-badge {
        margin: 0 10px 18px 10px;
        display: flex;
        background-color: white;
        border-radius: 4px;
        align-items: center;
        height: 65px;
        width: 160px;
        justify-content: center;
        box-shadow: 1px 1px 4px 0 #ddd;
        position: relative;
        align-items: flex-start;

        img {
            position: absolute;
            top: 7px;
            right: 7px;
            cursor: pointer;
        }

        img:hover + .v2-tooltip {
            display: block !important;
        }
    }

    .pi-badge>div:last-child {
        font-size: 14px;
        flex-basis: 100%;
        padding: 6px 0 0 15px;
    }

    .pi-badge p {
        margin-bottom: 3px;
    }
}

#PO>div:nth-child(2)>div:nth-child(1) {
    flex-basis: 40%;
    height: fit-content;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(174, 174, 174, 0.5);
    padding: 15px;
    position: relative;

    img {
        cursor: pointer;
    }

    img:hover + .v2-tooltip {
        display: block !important;
    }

    .chartjs-render-monitor {
        height: 200px !important;
    }
}

#PO>div:nth-child(2)>div:nth-child(1)>div:nth-child(2) {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    span:first-child {
        color: #6b7783;
        display: inline-block;
        margin-right: 10px;
    }

    span:nth-child(2) {
        color: #498bdc;
        font-size: 12px;
    }

    .sub__control {
        width: 140px !important;
        font-size: 12px !important;
        min-height: 25px !important;
        height: 25px !important;

        .sub__indicator-separator {
            margin: 0 !important;
        }

        .sub__dropdown-indicator {
            padding: 2px !important;
        }

        div {
            max-height: 100% !important;
        }
    }
}

#PO>div:nth-child(2)>div:nth-child(1)>div:nth-child(2)>div {
    margin-left: auto;
}

#PO>div:nth-child(2)>div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 60%;
    padding-left: 10px;
}

#PO>p {
    font-size: 18px;
}

#PO>:nth-child(4) {
    margin-top: -23px;
}

.modal {
    z-index: 1202 !important;
}

.modal-backdrop {
    z-index: 1201 !important;
}

.item-header {
    display: none;
}