
.categories {
  padding-left:10%;
  padding-top:100px;
}

.categories-pad{
	margin-left:60%;
}

.categories-header{
  font-family: Gotham-Book;
  font-size: 34px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: -0.6px;
  color: $ink;
 }

.categories-text{
  //font-family: Gotham-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: -0.2px;
  color: $ink;
} 

.categories-item-label{
  padding-left: 5%;
}
.categories-page-input { 
    &:hover {
        background: $sky-lighter;
        color: $ink-lighter;
    }
    &:focus {
        background: $sky-lighter;
        color: $ink-lighter;
    }
    @include button($sky-lighter, $teal-lighter, $ink-lighter,14px);
    padding: 0px 12px;
    width:300px;
    height:40px;
    cursor:text;
    opacity:0.5;
}

.card-body-child{
  padding:15px 0px 15px 72px;
}

.cat-view-wrapper{
  background-color: $sky-lightest !important;
}
.cat-view-container{
  display: flex;
  padding-left:1%;
  
}

.sub-cat-view-container{
  display: flex;
  padding-left:5%;
}

.sub-cat-view-container> .first, .cat-view-container> .first{
  width:60%;
  display: inline;
  text-align: left;
}

.cat-view-container> .first{
  width:62%;
}

.sub-cat-view-container > .second, .cat-view-container > .second{
  width:5%;
  display: inline
}

.cat-view-container> .second{
  padding-left: 4%;
}

.sub-cat-view-container > .third, .cat-view-container > .third{
  width:18%;
  display: inline;
}