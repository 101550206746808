
.tab_item{
	margin-left: 12px;
	background:$sky-lightest;
	border-radius:8px;
	text-align: center;
	width:30%;
	padding:30px 0px;
}

.tab_item_active{
	background:$blue-lightest;
	border:solid 1px $blue;
}