//This scss file contains ; very common styling which will be used in almost every component accross the project
//while devlopement if you feel that its a very commom styling & can be used accross the project then write it here only.
//please go through this scss file so that you will get to know some common & frequently usable classnames and their nomenclature.

.flex-row {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-evenly {
  display: flex;
  justify-content: space-evenly;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-row-left {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

//////////////////

.display-none {
  display: none;
}

.display-block {
  display: block;
}
///////////////////

.badge {
  margin-top: 2px;
  width: 93px;
  height: 24px;
  border-radius: 8px;
}

.badge-title {
  display: flex;
  justify-content: center;
  margin-top: 3px;
}
.badge-danger {
  background-color: $faded-pink;
  border: 1px solid $red-dark;
}
.badge-warning {
  background-color: $orange-lightest;
  border: 1px solid $orange-dark;
}
.badge-success {
  background-color: $teal-lightest;
  border: 1px solid $teal;
}

.validation_warning {
  color: #ba1800;
  font-size: 12px;
  margin-top: 1px;
  //font-family: Gotham-book;
}

///////////////////////////////

.width-5 {
  width: 5%;
}

.width-25 {
  width: 25%;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30% !important;
}

.width-35 {
  width: 35%;
}

.width-90 {
  width: 90%;
}

.width-80 {
  width: 80%;
}

.width-70 {
  width: 70%;
}

.width-60 {
  width: 60%;
}

.width-96 {
  width: 96%;
}

.width-47 {
  width: 47%;
}

.width-40 {
  width: 40%;
}

.width-45 {
  width: 45%;
}

.width-50 {
  width: 50%;
}

.width-33 {
  width: 33.3%;
}

.width-32 {
  width: 32%;
}

.width-66 {
  width: 66.6%;
}

.width-100 {
  width: 100% !important;
}

.height-95 {
  height: 95% !important;
}

.height-100 {
  height: 100% !important;
}

.width-60 {
  width: 60% !important;
}

.width-20 {
  width: 20%;
}

.width-10 {
  width: 10%;
}

.min-width-30 {
  min-width: 30% !important;
}
.min-width-60 {
  min-width: 60% !important;
}
.min-width-80 {
  min-width: 80% !important;
}

.min-width-45 {
  min-width: 45% !important;
}

.min-width-20 {
  min-width: 20% !important;
  width: 25%;
}

////////////////////////////

.pd-lt-35-cent {
  padding-left: 35%;
}
.pd-lt-44 {
  padding-left: 44px;
}
.pd-lt-40 {
  padding-left: 40px;
}
.pd-lt-28 {
  padding-left: 28px;
}
.pd-lt-5 {
  padding-left: 5px;
}
.pd-rt-5 {
  padding-right: 5px;
}
.pd-lt-10 {
  padding-left: 10px;
}
.pd-lt-12 {
  padding-left: 12px;
}
.pd-lt-16 {
  padding-left: 16px;
}
.pd-lt-20 {
  padding-left: 20px;
}
.pd-0-10 {
  padding: 0 10px;
}

.pd-0-12 {
  padding: 0 12px;
}

.pd-0-18 {
  padding: 0 18px;
}

.pd-0-30 {
  padding: 0 30px;
}
.pd-40 {
  padding: 40px;
}
.pd-20 {
  padding: 20px;
}
.pd-10 {
  padding: 10px;
}
.pd-12 {
  padding: 12px;
}
.pd-18 {
  padding: 18px;
}
.pd-10-8 {
  padding: 10px 8px;
}
.pd-20-0 {
  padding: 20px 0px;
}

.pd-20-8 {
  padding: 20px 8px;
}
.pd-10-18 {
  padding: 10px 18px;
}
.pd-10-20 {
  padding: 10px 20px;
}

.pd-15-18 {
  padding: 15px 18px;
}
.pd-20-18 {
  padding: 20px 18px;
}

.pd-18-0 {
  padding: 18px 0;
}

.pd-18-30 {
  padding: 18px 30px;
}

.pd-17-0 {
  padding: 17px 0;
}

.pd-14-0 {
  padding: 14px 0;
}

.pd-10-0 {
  padding: 10px 0;
}

.pd-20-30 {
  padding: 20px 30px;
}

.pd-24-30 {
  padding: 24px 30px;
}

.pd-5-20 {
  padding: 5px 20px;
}

.pd-8-20 {
  padding: 8px 20px;
}

.pd-30-20 {
  padding: 30px 20px;
}

.pd-30 {
  padding: 30px;
}

.pd-5 {
  padding: 5px;
}

.mg-3 {
  margin: 3px 0;
}

.mg-5 {
  margin: 5px 0;
}

.mg-8 {
  margin: 8px 0;
}

.mg-0-8 {
  margin: 0 8px;
}

.mg-10 {
  margin: 10px 0;
}

.mg-15 {
  margin: 15px 0;
}

.mg-20 {
  margin: 20px 0;
}

.mg-25 {
  margin: 25px 0;
}

.mg-30 {
  margin: 30px 0;
}

.mg-40 {
  margin: 40px 0;
}

.mg-60 {
  margin: 60px 0;
}

.mg-80 {
  margin: 80px 0;
}

.mg-top-4 {
  margin-top: 4px;
}

.mg-top-10 {
  margin-top: 10px;
}

.mg-top-15 {
  margin-top: 15px;
}

.mg-top-18 {
  margin-top: 18px;
}

.mg-top-20 {
  margin: 20px 0px 0px;
}

.mg-top-30 {
  margin: 30px 0px 0px;
}

.mg-top-40 {
  margin: 40px 0px 0px;
}

.mg-top-56 {
  margin-top: 56px;
}

.mg-lt-2-cent {
  margin-left: 2%;
}

.mg-lt-8 {
  margin-left: 8px;
}

.mg-lt-10 {
  margin-left: 10px;
}

.mg-lt-12 {
  margin-left: 12px;
}

.mg-lt-18 {
  margin-left: 18px;
}

.mg-lt-44 {
  margin-left: 44px;
}

.mg-lt-28 {
  margin-left: 28px;
}

.mg-lt-40 {
  margin-left: 40px;
}

.mg-rt-8 {
  margin-right: 8px;
}

.mg-rt-10 {
  margin-right: 10px;
}

.mg-rt-12 {
  margin-right: 12px;
}

.mg-rt-18 {
  margin-right: 18px;
}

.mg-rt-20 {
  margin-right: 20px;
}

.mg-rt-25 {
  margin-right: 25px;
}

.mg-lt-20 {
  margin-left: 20px;
}

.mg-lt-24 {
  margin-left: 24px;
}

.mg-lt-30 {
  margin-left: 30px;
}

.mg-bot-40 {
  margin-bottom: 40px;
}

.mg-bot-30 {
  margin-bottom: 30px;
}

.mg-bot-25 {
  margin-bottom: 25px;
}

.mg-bot-20 {
  margin-bottom: 20px;
}

.mg-bot-18 {
  margin-bottom: 18px;
}

.mg-bot-15 {
  margin-bottom: 15px;
}

.mg-bot-12 {
  margin-bottom: 12px;
}

.mg-bot-10 {
  margin-bottom: 10px;
}

.mg-bot-8 {
  margin-bottom: 8px;
}

.mg-bot-5 {
  margin-bottom: 5px;
}

.mg-bot-4 {
  margin-bottom: 4px;
}

////////////////////////////

.font-medium-12-red-dark {
  font-family: Gotham-Medium;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $red-dark;
}

.font-medium-12-blue {
  font-family: Gotham-Medium;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $blue;
}

.font-medium-14-blue {
  font-family: Gotham-Medium;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $blue;
}

.font-medium-14-lighter {
  font-family: Gotham-Medium;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $ink-lighter;
}

.font-book-14-lighter {
  //font-family: Gotham-Book;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: $ink-lighter;
}

.font-book-14-ink {
  //font-family: Gotham-Book;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: $ink;
}

.font-book-16-ink {
  //font-family: Gotham-Book;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: #212b36;
}

.font-medium-14-ink {
  //font-family: Gotham-Medium;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $ink;
}

.font-medium-16-lighter {
  font-family: Gotham-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $ink-lighter;
}

.font-medium-16-ink {
  font-family: Gotham-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -0.2px;
  color: $ink;
}

.font-medium-20-ink {
  font-family: Gotham-Medium;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -0.2px;
  color: $ink;
}

.font-medium-20-white {
  //font-family: Gotham-Book;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $white;
}

.font-medium-20-black {
  // font-family: Gotham-Book;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
}

.font-medium-24-lighter {
  font-family: Gotham-Medium;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $ink-lighter;
}

.font-medium-24-book {
  //font-family: Gotham-Book;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $ink;
}

.font-medium-24-book-blue {
  //font-family: Gotham-Book;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $blue;
}

.font-book-34-ink {
  font-family: Gotham-Medium;
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: -0.6px;
  color: $ink;
}

.rel-left-12-btm-4 {
  position: relative;
  left: 12px;
  bottom: 4px;
}

.rel-left-12 {
  position: relative;
  left: 12px;
}

.text_14_600_242424 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
}

.text_14_600_333853 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #333853;
}

.text_16_600_333853 {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #333853;
}

.text_16_normal_333853 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #333853;
}

.text_16_600_7b7f82 {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #7b7f82;
}

.text_16_normal_7b7f82 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #7b7f82;
}

.text_16_bold_242424 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
}

.text_18_bold_242424 {
  font-size: 18px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
}

.text_20_normal_333853 {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #333853;
}

.text_24_normal_333853 {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #333853;
}

.text_24_600_333853 {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #333853;
}

.lineThroughStyle {
  text-decoration-line: line-through;
  text-decoration-color: #ba1800;
}

.discPerctStyle {
  background: #ba1800;
  padding: 4px;
  border-radius: 4px;
}

.textOverflow {
  // width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
