
.catalogue{
  padding-left:8%;
  padding-right:8%;
  padding-top:64px;
}

.form-group {
  //margin-bottom: 30px !important;

  .form-control {
    border-radius: 4px !important;
  }
}

.custom-card {
  border-radius: 4px !important;
}

.sub__control {
  border-radius: 4px !important;
}

.sub__indicators {
  border-radius: 0px 4px 4px 0px !important;
}

.catalogue-header{
  font-family: Gotham-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $blue;
 }

.new-product{
  //font-family: Gotham-Book;
  font-size: 34px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: -0.6px;
  color: $ink;
  float:left;
  padding-top:10px;
 } 

 .cancel{
   margin-left:53.5%;
 }

 .save{
  margin-left:12px;
 }

 .create-row{
   display:flex;
   flex-direction:row;
   margin-top:70px;
 }

 .create-col-1{
   flex-basis:67%;
 }

 .create-col-2{
   margin-left:18px; 
   flex-basis:28%;
 }

.create-text-area{
 &::placeholder{
   color:$ink;
 }
  width:85%;
  height:80px;
  font-family: Gotham-Medium;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.2px;
  color: $ink;
  border:solid 1px $teal-lighter;
  border-radius:4px;
  margin-bottom:30px;
  padding:12px 18px 12px 18px;  
}

.create-title-div{
  padding:30px 0px 30px 36px;
}

.create-sell-items-div{
  margin-top:18px;  
  padding:30px 0px 0px 36px;
}

.create-inventory-div{
  padding:30px 0px 30px 36px;
  margin-top:18px;
}

.create-attach-div{
  position:relative;
  margin-top:18px;  
  padding:30px 0px 30px 36px;
}

.create-image-div{
  padding:21px 0px 26px 24px;
}

.create-image{
  margin-top:26px;
  margin-left:25%;
  margin-bottom:24px;
  width:40%;
  height:114px;
}

.browse-image{
  margin-left:20%;
}

.drag-drop-img{
  margin-left:15%;
}

.create-organization-div{
  margin-top:16px;
  padding:24px 0px 24px 24px;
}

.create-img-shop{
  margin-top:30px;
  margin-left:50%;
}

.create-additional-options-div{
  margin-top:18px;
  padding:24px 0px 24px 24px; 
}

.rupees{
  padding:8px;
  border:solid 1px $teal-lighter;
  border-radius:4px 0px 0px 4px;
  background: $sky-lighter;
  border-right:none;
}

.rupees-input{
  position:relative;
  bottom:16px;
  border-radius:0px 4px 4px 0px;
}

.stock{
  display:flex;
  flex-direction:row;
  height:64px;
}


.stock-info{
  position:absolute;
  width:230px;
  height:140px;
  background:$orange-lightest;
  border:solid 1px $orange-dark; 
  border-radius:4px;
  left:200px;
  bottom:70px;
  padding:12px 18px 18px 18px;
  font-size:12px;
  color:$green-text;
}

.orange-dark{
  width:28px;
  height:28px;
  background-color:$orange-lighter;
  display:block;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:6px;
  border-radius:4px;
  padding:4.5px 0px 0px 4.5px;
}

.calc{
  position:relative;
  height:40px;
  top:12px;
}

.cal-input{
  position:relative;
  height:100%;
  width:100%;
  border:solid 1px $teal-lighter;
  text-indent:20px;
}

.plus{
  position:absolute;
  border-radius:0px 4px 0px 0px;
  height:50%;
  border:solid 1px $teal-lighter;
  border-bottom:0px;
  width:40px;
  right:0.1px;
  background:$sky-lighter;
}

.minus{
  position:absolute;
  border-radius:0px 0px 4px 0px;
  height:50%;
  border:solid 1px $teal-lighter;
  width:40px;
  right:0.1px;
  background:$sky-lighter;
  top:20px;
}

.store-price-label{
  position: absolute;
  top:24%;
  left:74%;
  letter-spacing: -0.2px;
  font-size:14px;
  color:$blue;
}

.custom-rupee-container{
  position: relative;
}