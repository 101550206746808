#generic_report6 {
    #filters_div>div:first-child {
        flex-basis: 42% !important;
        border: none !important;
        color: #505050 !important;
    }
}

#generic_report6>div:nth-child(2)>div:nth-child(1) {
    background-color: #0000 !important;
    box-shadow: none !important;
    display: flex !important;
    flex-basis: 100% !important;
    flex-wrap: wrap !important;
}

#generic_report6 {
    .react-bootstrap-table {
        th:last-child {
            text-align: center !important;
        }

        td:last-child {
            display: flex !important;
            justify-content: center !important;
            width: auto !important;
        }
    }
}