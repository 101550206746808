#view_po_div {
    padding-top: 10px !important;
    padding-left: 7.5% !important;
    padding-right: 2.8% !important;

    #main_div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: start;
    }

    #main_div>div:first-child {
        background-color: white;
        border-radius: 4px;
        width: 71%;
        padding-top: 20px;
    }

    #main_div>div:first-child>p {
        margin-bottom: 40px !important;
        color: #498bdc;
    }

    #main_div>div:first-child>p {
        padding: 0 30px;
    }

    #main_div>div:first-child>div {
        padding: 0 30px;

        .product-item {

        }

        .product-item-head {
            display: flex;
            background-color: white;
            border-radius: 4px;
            height: 70px;
            color: #212b36;
            font-size: 14px;
            font-weight: lighter;
            align-items: center;
            margin-bottom: 18px;

            img:first-child {
                height: 90%;
                width: auto;
                margin-right: 38px;
                margin-left: 18px;
            }

            img:last-child {
                width: fit-content;
                margin-left: 10px;
            }
        }

        .product-item-head:hover {
            background-color: #e0e0e0;
            cursor: pointer;
        }

        .product-item-head>div {
            display: flex;
            flex-grow: 2;
            height: 100%;
            align-items: center;

            p {
                margin: 2px 0 !important;
            }

            div {
                width: 22.22%;
                text-align: right;
            }

            div:first-child {
                width: 33.33%;
                text-align: left;
            }
        }

        .product-item-head>div>p {
            margin: 2px 10px !important;
        }

        .product-item-body {
            height: 0;
            overflow: hidden;
            transition: ease 0.3s all;
            background-color: #498bdc;
            color: white;
            padding: 0 20px;
        }

        .product-item-body>div {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-bottom: 10px;

            div {
                margin-right: 15px;
            }
        }

        .product-item-body>div:last-child {
            margin-bottom: 20px !important;
        }
    }

    #main_div>div:first-child>div:nth-child(2) {
        height: 400px;
        overflow-y: scroll;
    }

    #main_div>div:first-child>div:nth-child(3) {
        padding: 30px 30px 30px 30px;
        background-color: #fffaec;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-end;
        flex-direction: column;
        border-top: dashed 2px #d0d0d0;
    }

    #main_div>div:first-child>div:nth-child(3)>div {
        display: flex;
        color: #485562;
        margin-bottom: 10px;
    }

    #main_div>div:first-child>div:nth-child(3)>div:last-child {
        font-weight: bold;
    }

    #main_div>div:first-child>div:nth-child(3)>div>div {
        width: 100px;
        text-align: right;
    }

    #main_div>div:last-child {
        width: 27%;
    }

    #main_div>div:last-child>div:nth-child(1), #main_div>div:last-child>div:nth-child(2) {
        background-color: white;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 12px;

        span {
            width: 49%;
            display: inline-block;
            font-size: 14px;
            color: #6b7783;
        }

        span:nth-child(2) {
            width: 1%;
        }

        span:last-child {
            width: 50%;
            color: #485562;
            padding-left: 10px;
        }
    }

    #main_div>div:last-child>div:nth-child(1)>div:first-child, #main_div>div:last-child>div:nth-child(2)>div:first-child {
        color: #498bdc;
        margin-bottom: 20px;
    }

    #main_div>div:last-child>div:nth-child(3) p:first-child {
        margin-bottom: 8px;
    }

    #main_div>div:last-child>div:nth-child(3) p:last-child {
        font-size: 12px;
        color: #acb3ba;
    }
}

#view_po_div>div:first-child {
    display: flex;
    margin-bottom: 30px;

    img {
        height: 20px;
        margin: 6px 20px 0 0;
    }

    div {
        span:first-child {
            font-size: 20px;
        }

        span:last-child {
            font-size: 11px;
            color: #212b36;
        }
    }

    button:last-child {
        background-color: #498bdc;
        color: white;
        height: 38px;
        border-radius: 5px;
        border: none;
        width: fit-content;
        font-size: 14px;
        margin-right: 5px;
        margin-left: auto;
        padding: 0 20px;
    }
}