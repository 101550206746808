             

.webHeaderBox {
    background-image: var(--bgImg) !important;
  }

  .lignt,input[type="radio"]:checked:after, .newActiveTab , .btnBlue,  .otpInputDark, .titleBarBlue,  .decrementBtn, .incrementBtn {
    background-color: var(--bgClrLight) !important;
  }

  
  .drd,.blue14Btn:hover,  .blueHeaderBox, .viewCart,.btnBlue:hover, .longBtnBlue:hover,.extraHead {
    background-color: var(--bgClrDark) !important;
  }

  .blueHeaderBox,.itcWebHeaderBox{
   background-image: var(--bgImg) !important;
  }  

  .btnWhite {
     color: var(--bgClrLight) !important;
    border: var(--bgClrLight) !important;  
  }

  .btnWhite:hover,.text_16_500_498bdc,.text_20_normal_498bdc_SFReg {
      color: var(--bgClrLight) !important;
  }

 .longBtnBlue {
   background-color: var(--bgClrLight) !important;             
   color: #fff !important;       
   border: var(--bgClrLight) !important;         
 }
              
              
 .btnDark {
   background-color: var(--bgClr1) !important;           
   color: #fff !important;           
   border: var(--bgClr1) !important;
 }
              
 .btnDark:hover {
   background-color: var(--bgClr1) !important;
 }


 .btnGrey{     
    border: var(--bgClrLight) !important;
 }         
            
.blueMarker {
   background: rgba(28, 94, 187, 0.84) !important;
 }

.clearCart,.unitBtnMob{
  border: var(--border1) !important;
}

.addBtn { 
    color: var(--bgClrLight) !important; 
    border: var(--border2) !important;
 }


.selectedSlot {           
  border: var(--border1) !important;
  background-color: var(--bgClrLight) !important;
}

.blueBar,.cont {
  background-color: var(--bgClrLight) !important;
}


.activeTabWeb {
  border-bottom: var(--border3) !important; 
  color: var(--bgClrLight) !important;
}

.searchIconContainer{
  color: #fff !important;
}

.page-item.active .page-link,.proceed {
  background-color: var(--bgClrDark) !important;
  border-color: var(--bgClrDark) !important;
}

.btn-outline-primary ,.longBtnWhite, .borcol, .btnSecn, .white14Btn{
  color: var(--bgClrDark) !important;
  border-color: var(--bgClrDark) !important;
}
.btn-outline-primary:hover,.btnPrim,.blue14Btn{
  color: #FFF !important;
  background: var(--bgClrDark) !important;
  border-color: var(--bgClrDark) !important;
}

.grpname, .text_14_500_498bdc,.text_14_normal_498bdc_SFReg, .text_16_600_498bdc_SFSbold ,.text_16_normal_498bdc_SFReg,.text_18_600_498bdc_SFSbold,.text_18_normal_498bdc_SFReg ,.text_14_bold_498bdc{
  color: var(--bgClrDark) !important;
}

.filtersvg, .spinner{
  filter: var(--filterInvert) !important;
}

.decrementBtn,.incrementBtn{
  color: #fff !important;
}


.text_14_bold_498bdc, .text_12_500_498bdc,.text_20_600_498bdc_SFSbold,.text_14_normal_498bdc,button .longBtnWhite:hover,a:hover,.text_14_600_498bdc_SFSbold,.text_12_normal_498bdc,.text_12_600_498bdc{
  color: var(--bgClrLight) !important;
}
.btnBlue,.decrementBtn,.incrementBtn{
  border: var(--bgClrDark) !important;
}

.btn-primary,.btn-primary:hover{
  background-color:var(--bgClrDark) !important;
  border-color: var(--bgClrDark) !important;
}


.mugutheme{
  background-image: var(--bgImg) !important;

}

.lottieStyles{
  filter: var(--filterInvert) !important;
}

.borderedElement{
  border-color:  var(--bgClrDark) !important;
}
.themeClrDark{
  background: var(--bgClrDark);
}