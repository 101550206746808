#create_po_div {
    padding-top: 10px !important;
    padding-left: 7.5% !important;
    padding-right: 2.8% !important;

    #main_div {
        background-color: white;
        border-radius: 4px;
        overflow: hidden;

        hr {
            border-top: dashed 2px #bbc0c6 !important;
        }

        #basic_info_div {
            margin: 30px;
        }

        #basic_info_div>div:first-child {
            color: #498bdc;
            font-size: 14px;
        }

        #basic_info_div>div:nth-child(2) {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;

            label {
                color: #6b7783;
                font-size: 14px;
                margin-bottom: 8px;
            }

            .sub__control {
                width: 240px;
                min-height: 30px !important;
                height: 30px !important;
                border-radius: 4px; 
            }

            .sub__control>div {
                height: 28px;
                border-radius: 4px !important;
            }

            input {
                display: block;
                height: 30px;
                border: solid 1px #bbc0c6;
                width: 240px;
                border-radius: 4px;
                padding-left: 10px; 
            }
        }

        #basic_info_div>div:nth-child(2)>div {
            margin-right: 30px;
            margin-bottom: 20px;
        }

        #basic_info_div>div:nth-child(2)>div:last-child {
            input {
                display: inline-block !important;
                width: fit-content;
                height: fit-content;
                margin-right: 6px;
            }
        }

        #add_products_div {
            margin: 30px 30px 50px 30px;
        }

        #add_products_div>div:first-child {
            display: flex;
            justify-content: space-between;
            color: #498bdc;
            font-size: 14px;
            margin-bottom: 44px;

            button {
                color: #498bdc;
                font-size: 14px;
                padding: 5px 12px;
                background-color: white;
                border-radius: 4px;
                margin-right: 12px;
                box-shadow: 0 2px 2px 0 rgba(58, 55, 55, 0.1);
                border: solid 0.5px #bbc0c6;
            }
        }

        #add_products_div>div:first-child>div {
            display: flex;
        }

        #add_products_div>div:first-child>div>div {
            position: relative;

            button {
                margin-right: 0 !important;
            }

            ul {
                display: none;
                position: absolute;
                right: 0;
                top: 35px;
                background-color: white;
                box-shadow: 0 0 9px 0 #d0d0d0;
                border-radius: 5px;
                overflow: hidden;
                width: 100%;
                list-style: none;
                padding: 0;
                z-index: 2;

                li {
                    color: #6b7783;
                    padding: 10px 0;
                    text-align: center;
                    cursor: pointer;
                }

                li:hover {
                    background-color: #e0e0e044;
                }
            }
        }

        .custom-search-field {
            right: 61px;
        }

        .custom-table {
            border-radius: 10px;
            box-shadow: 0 -1px 5px 0px rgba(0, 0, 0, 0.2);
            margin-top: 16px;
    
            thead {
                background-color: white;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border: none;
            }
    
            th:first-child {
                border-top-left-radius: 10px !important;
                width: 5% !important;
            }
    
            th:last-child {
                border-top-right-radius: 10px !important;
            }
    
            th {
                padding-top: 20px !important;
                border: none;
                width: 19% !important;
                color: #485562;
            }
    
            td {
                border: none !important;
                padding: 20px !important;
                width: 19% !important;
            }
    
            td:first-child {
                width: 5% !important;
            }
        }

        #confirm_added_products_div {
            margin: 30px 30px 50px 30px;
        }

        #confirm_added_products_div>div:first-child {
            color: #498bdc;
            font-size: 14px;
            margin-bottom: 30px;
        }

        #billing_div {
            padding: 30px 30px 50px 30px;
            background-color: #fffaec;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        #billing_div>div:first-child {
            display: flex;
        }

        #billing_div>div:first-child>div {
            width: 50%;
            padding: 9px;

            p {
                color: #6b7783;
                margin-bottom: 8px;
            }

            textarea {
                border-radius: 4px;
                border: solid 1px #bbc0c6;
                padding: 8px;
                height: 100px;
            }
        }

        #billing_div>div:last-child {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            #total_val {
                color: #212b36;
                font-size: 20px;
            }

            input {
                width: 50px;
                height: 22px;
            }

            .sub__control {
                width: 50px;
                min-height: 22px !important;
                height: 22px !important;
                border-radius: 4px; 
            }

            .sub__control>div {
                height: 20px;
                border-radius: 4px !important;
            }
        }

        #billing_div>div:last-child>div {
            display: flex;
            margin-bottom: 8px;
        }

        #billing_div>div:last-child>div>div {
            text-align: right;
            min-width: 187px;
            font-size: 14px;
        }

        #discount_options {
            display: flex;

            input {
                margin-left: 8px;
                border: solid 1px #a5cfcf;
                border-radius: 4px;
            }

            .sub__control {
                width: 52px;
                flex-wrap: nowrap;
            }

            .sub__value-container {
                padding: 0;
            }

            .sub__single-value {
                padding-top: 3px;
                padding-left: 2px;
            }

            .sub__indicators {
                width: 23px;
            }

            .sub__dropdown-indicator {
                padding: 0;
            }
        }

        #discount_options>div {
            margin-left: 8px;
        }

        #tax_div {
            flex-direction: column;

            .tax-item {
                display: flex;
                margin-bottom: 8px;
                width: 100% !important;

                div {
                    text-align: right;
                    min-width: 187px;
                }
            }
        }
        
        .sub__value-container {
            flex-wrap: nowrap;
        }
    }

    .pagination {
        float: right;
    }
}

#create_po_div>div:first-child {
    display: flex;
    margin-bottom: 30px;
    position: sticky;
    top: 57px;
    background-color: #EFF1F3;
    z-index: 900;
    padding: 20px 0 10px 0;

    img {
        height: 20px;
        margin: 6px 20px 0 0;
    }

    div {
        span:first-child {
            font-size: 20px;
        }

        span:last-child {
            font-size: 11px;
            color: #212b36;
        }
    }

    button:nth-child(3) {
        margin-left: auto;
        background-color: #0000;
        color: #498bdc;
        height: 38px;
        border-radius: 5px;
        border: solid 1px #498bdc;
        width: 87px;
        font-size: 14px;
        margin-right: 5px;
    }

    button:last-child {
        background-color: #498bdc;
        color: white;
        height: 38px;
        border-radius: 5px;
        border: none;
        width: fit-content;
        font-size: 14px;
        margin-right: 5px;
        padding: 0 20px;
    }
}