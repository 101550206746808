
.home {
  background-color: $sky-lighter;
  width: auto;
  padding-left: 13%;
  padding-top: 100px;
}

.welcome {
  margin-bottom: 25px;
  width: 100%;
  background-color: $sky-lighter;
}

.welcome-text {
  //font-family: Gotham-Book;
  font-size: 24px;
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: $ink-lighter;
}

.select-home {
  margin-left: 1%;
  width: auto;
  height: 52px;
  outline: none;
  min-width: 237px;
  border: 1px solid $teal-lighter;
  border-style: dashed;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 18px;
  font-size: 18px;
  color: $ink-lighter;
  background: url('../../icons/down-arrow.png') no-repeat;
  background-position: 96%;
}

.row-1 {
  display: flex;
  flex-direction: row;
}

.col-1-1 {
  flex-basis: 16%;
  padding: 15px;
}

.col-1-2 {
  flex-basis: 74%;
  margin-left: 2%;
  padding: 18px 0px 0px 36px;
}


.col-1-0 {
  flex-basis: 90%;
  padding: 18px 0px 0px 36px;
}

.row-2 {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.col-2-1 {
  flex-basis: 50%;
  padding: 15px;
}

.col-2-1-75{
  flex-basis: 75%;
  padding: 36px;
}
.col-2-1-100{
  flex-basis: 100%;
  padding: 15px;
}

.col-2-2 {
  flex-basis: 50%;
  margin-left: 2%;
  padding: 15px;
}
/////
.col-100p{
  flex-basis: 100%;
  padding: 10px 20px 10px 20px;
}
.col-80p{
  flex-basis: 78.5%;
  padding: 10px 20px 10px 20px;
}

.col-50p{
  flex-basis: 49%;
  padding:15px;
}

.col-60p{
  flex-basis: 57%;
  padding:15px;
}

.col-70p{
  flex-basis: 68%;
  padding: 15px;
}

.col-30p{
  flex-basis: 30%;
  padding: 15px;
}

.col-20p{
   flex-basis: 20%;
   padding: 10px 20px 10px 20px;
}

.subcontainer {
  padding: 18px 0px 30px 36px;
  float: left;
  color: $ink-lighter;
}

.label-header {
  font-family: Gotham-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: $blue;
}

.sub-title-text {
  font-family: Gotham-Medium;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: $ink-lighter;
}
.sub-title-description{
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #bbc0c6;
}

.lg {
  font-size: 24px;
}

.price {
  //font-family: Gotham-Book;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: $ink;
}

.label-text {
  //font-family: Gotham-Book;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.3px;
  color: $ink;
}

.tab-content {
  display: flex;
  & span {
    color: $ink-lighter;
    font-size: 16px;
  }
  & div.name {
    align-items: center;
    display: block;
    color: $ink-lighter;
    padding: 10px;
    & .product-attribute {
      display: block;
      color: $ink;
    }
  }
  & div.count {
    padding-top: 10px;
    color: $ink-lighter;
  }
  & .images {
    width: 15%;
    & img {
      height: 55px;
      width: 55px;
      border-radius: 8px;
      padding: 5px;
      min-width: 55px;
      min-height: 55px;
      background-color: $img-def-bg-color;
    }
  }
  & .name {
    flex: 1;
  }
}

span.select-home {
  padding: 15px;
  padding-right: 35px;
  border-radius: 8px;
  cursor: pointer;
}