#generic_report3 {
    #filters_div>div:first-child {
        flex-basis: 62% !important;
        border: solid 1px#707070 !important;
        color: #707070 !important;
    }
}

#generic_report3>div:nth-child(2)>div:nth-child(1) {
    flex-basis: 40% !important;
    background-color: white !important;
    box-shadow: 0 1px 2px 0 rgba(174, 174, 174, 0.5) !important;
    display: block !important;
}